import { type ActionFunctionArgs } from 'react-router-dom';
import { type Analytic } from 'types/analytic';
import { getAnalytic } from 'domains/api/getAnalytic';

export const analyticLoader = async ({
  params,
}: ActionFunctionArgs): Promise<Analytic> => {
  const response = getAnalytic(params);

  return await response;
};
